<template>
  <div>
    <page-title :heading="$t('generic.lang_foodDelivery')" :icon=icon :subheading="$t('generic.lang_foodDelivery')"
    ></page-title>
    <div class="app-main__inner">
      <meal-checkout/>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/Layout/Components/PageTitle";
import MealCheckout from "@/components/kantinopel/MealCheckout";


export default {
  name: "CanteenMenu",
  components: {
    MealCheckout,
    PageTitle,
  },
  data: () => ({
    icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>
