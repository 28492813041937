<template>
  <v-container class="px-0 mx-0 pa-0" fluid>
    <div>
      <v-card class="mx-auto">
        <v-card-title>
          <v-spacer></v-spacer>
          <div class=" mr-5">
            <p>{{ this.time }}</p>
          </div>
        </v-card-title>

        <v-card-text>
          <v-list subheader two-line>
            <v-list-item v-for="(order) in orders" :key="order.uuid">
              <v-list-item-content :class="`rounded${'-lg'}`" class="border mb-5 pa-md-0 mx-lg-auto">
                <v-list-item-title class=" mt-5 ml-5  mr-10">
                  <v-row>
                    <v-col class="custom7cols" cols="3">
                      <H4>{{ order.lunchbee_user.preName }} {{ order.lunchbee_user.lastName }}</H4>
                      <H4>{{ order.lunchbee_user.id }}</H4>
                    </v-col>
                    <v-col class="custom7cols" cols="3">
                      <H4 class="deep-orange--text mb-6">{{ order.class }} {{ order.group }}</H4>
                      <H6 class="grey--text mb-6">
                        {{ order.scanned_at }}
                      </H6>
                    </v-col>
                    <v-col class="custom7cols" cols="3" align="right">
                      <v-btn v-if="alreadyScanned(order)" class="ma-2" outlined color="red">
                        <v-icon color="red lighten-1">mdi-information</v-icon>
                        <span style="margin-left: 15px;">{{ $t('generic.lang_mapAlreadyScanned') }}</span>
                      </v-btn>
                    </v-col>
                    <v-col class="custom7cols" align="right" cols="3">
                      <div v-for="(item,i) in order.items" :key="i">
                        <H4> {{item.qty}}x {{ item.shortname }} </H4>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
          </v-list>
        </v-card-text>
      </v-card>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import Datatable from "@/components/datatable/Datatable";
import VueHtml2pdf from 'vue-html2pdf'
import {ENDPOINTS} from "@/config";

export default {
  name: "MealCheckout",

  components: {Datatable, 'vue-html2pdf': VueHtml2pdf},

  mixins: [mixin],

  data: () => {
    return {
      interval: null,
      time: null,
      hours: "00",
      minutes: "00",
      seconds: "00",
      year: "00",
      month: "00",
      day: "00",

      orders: [],
      scanWarning: [],

      // BARCODE SCANNER
      barcodeCodeString: "",
      barcodeClearID: "",
      pause: false
    }
  },

  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
    }, 1000)
  },

  mounted() {
    //DISABLE GLOBAL BARCODE SCANNER
    this.$root.$emit("barcodeScanner.pause");

    window.addEventListener("keypress", this.addToBarcode);
    this.barcodeKeyInterval();
  },

  beforeDestroy() {
    // ENABLE BARCODE SCANNER
    this.$root.$emit("barcodeScanner.unpause");

    // prevent memory leak
    clearInterval(this.interval)

    // BARCODE SCANNER
    window.removeEventListener('keypress', this.addToBarcode);
    clearInterval(this.barcodeClearID);
  },

  methods: {
    scan(query) {
      // CHECK QUERY
      if(query.includes("$")) {
        query = query.split("$");
        query = query[1];
      }

      // PRE CHECK
      // CHECK IF ORDER ALREADY ADDED
      let orderToCheck = this.orders.find((orderFind) => orderFind.accountUUID === query);

      if(orderToCheck) {
        this.scanWarning.push(orderToCheck.uuid);

        return;
      }


      this.axios.post(ENDPOINTS.KANTINE.MEALCHECKOUT.SCAN, {
        query: query //"100001"
      }).then((res) => {
        if(res.data.success) {
          res.data.orders.forEach((order) => {
            // CHECK IF ORDER ALREADY ADDED
            let orderToCheck = this.orders.find((orderFind) => orderFind.uuid === order.uuid);

            if(!orderToCheck) {
              // NOT SCANNED
              this.orders.unshift(order);

              this.markAsServed(order);
            }
            else {
              this.scanWarning.push(order.uuid);
            }
          })
        }
      }).catch(() => {

      })
    },
    markAsServed(order) {
      this.axios.post(ENDPOINTS.KANTINE.MEALCHECKOUT.SERVE, {
        uuid: order.uuid
      }).then(() => {

      }).catch(() => {

      });
    },
    alreadyScanned(order) {
      return this.scanWarning.includes(order.uuid);
    },
    addToBarcode(e) {
      //CHECK IF WE SHOULD SKIP INPUTS
      if (this.pause)
        return;

      if (e.which === 13) {
        e.preventDefault();
        this.returnBarcode();
      } else {
        this.barcodeCodeString += String.fromCharCode(e.keyCode);
      }
    },
    returnBarcode() {
      this.scan(this.barcodeCodeString);

      this.clearBarcodeString();
    },
    clearBarcodeString() {
      this.barcodeCodeString = "";
    },
    //RESET SET
    barcodeKeyInterval() {
      this.barcodeClearID = setInterval(() => {
        this.clearBarcodeString()
      }, 700);
    },
  }
}
</script>

<style scoped>
.custom7cols {
  width: 100%;
  max-width: 100%;
}

.space {
  width: 10%;
  max-width: 10%;
}
</style>